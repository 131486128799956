import React, { useEffect, useState } from 'react';
import { cn } from '@superside/ui';
import { Box, HeaderLogo } from '@konsus/superside-kit';
import { useVerticalScroll } from '@konsus/hooks/src/useVerticalScroll';
import { ProgressiveModalEnabledCta } from '@konsus/lego/src/components/HeroCtaGroup';
import { type WrapperLogoWithStickyCtaProps, STICKY_CTA_POSITIONS } from '../types';
import { Wrapper } from './Wrapper';
import wrapperStyles from './Wrapper.module.css';
import useGlobalScrollPosition from './useGlobalScrollPosition';
import useWindowHeight from './useWindowHeight';

export const WrapperWithStickyCta: React.FC<WrapperLogoWithStickyCtaProps> = ({
  ctaButton,
  isAlwaysShown = false,
  firstSectionHidden = false,
  lastSectionHidden = false,
  heightOffset = 200,
  position = STICKY_CTA_POSITIONS.TOP,
  themeSwitchEnabled,
  background,
  children
}) => {
  const isTopPosition = position === STICKY_CTA_POSITIONS.TOP;
  const scrollPosition = useGlobalScrollPosition();
  const { windowHeight, pageHeight } = useWindowHeight();
  const [alwaysOn, setAlwaysOn] = useState(false);

  useEffect(() => {
    if (windowHeight && pageHeight) {
      const isFirstSectionHidden = firstSectionHidden && scrollPosition > windowHeight / 2;
      const isLastSectionHidden = scrollPosition + windowHeight < pageHeight - heightOffset;

      if (lastSectionHidden) {
        setAlwaysOn(isFirstSectionHidden && isLastSectionHidden);
      } else {
        setAlwaysOn(isFirstSectionHidden);
      }
    }
  }, [
    firstSectionHidden,
    heightOffset,
    lastSectionHidden,
    pageHeight,
    scrollPosition,
    windowHeight
  ]);

  if (!isAlwaysShown) {
    return (
      <ScrollableWrapperStickyCta
        ctaButton={ctaButton}
        isTopPosition={isTopPosition}
        themeSwitchEnabled={themeSwitchEnabled}
        background={background}
      >
        {children}
      </ScrollableWrapperStickyCta>
    );
  }

  return (
    <LogoWithCta
      isTopPosition={isTopPosition}
      alwaysOn={alwaysOn}
      ctaButton={ctaButton}
      themeSwitchEnabled={themeSwitchEnabled}
      background={background}
    >
      {children}
    </LogoWithCta>
  );
};

export const ScrollableWrapperStickyCta: React.FC<
  { isTopPosition: boolean; children: React.ReactNode } & Pick<
    WrapperLogoWithStickyCtaProps,
    'ctaButton' | 'themeSwitchEnabled' | 'background'
  >
> = (props) => {
  const { ctaButton, isTopPosition, themeSwitchEnabled, background, children } = props;

  const isDown = useVerticalScroll({ minHeight: -1 });

  return (
    <LogoWithCta
      isTopPosition={isTopPosition}
      alwaysOn={isDown}
      ctaButton={ctaButton}
      themeSwitchEnabled={themeSwitchEnabled}
      background={background}
    >
      {children}
    </LogoWithCta>
  );
};

const LogoWithCta: React.FC<
  { isTopPosition: boolean; alwaysOn: boolean; children: React.ReactNode } & Pick<
    WrapperLogoWithStickyCtaProps,
    'ctaButton' | 'themeSwitchEnabled' | 'background'
  >
> = ({ ctaButton, isTopPosition, alwaysOn, children, themeSwitchEnabled, background, ...rest }) => (
  <Wrapper
    isLpStickyCta={isTopPosition && alwaysOn && !themeSwitchEnabled}
    tag='header'
    wrap
    direction='column'
    fill='horizontal'
    light={themeSwitchEnabled}
    background={background}
    {...rest}
  >
    <Box
      direction='row'
      align='center'
      justify='between'
      className={cn(wrapperStyles.boxStyle, 'dark')}
    >
      <HeaderLogo />

      {ctaButton?.ctaText ? (
        <Box
          justify='center'
          className={cn(
            wrapperStyles.stickyCtaWrapper,
            isTopPosition
              ? wrapperStyles.ctaTopPositionStyle
              : wrapperStyles.ctaBottomPositionStyle,
            alwaysOn ? wrapperStyles.showButton : wrapperStyles.hideButton
          )}
        >
          <ProgressiveModalEnabledCta
            cta={ctaButton}
            ctaOption='primary'
            className={cn(
              isTopPosition && wrapperStyles.topButtonStyle,
              'marble:!bg-dark marble:!text-light marble:!shadow-none'
            )}
          />
        </Box>
      ) : null}
    </Box>
    {children}
  </Wrapper>
);
