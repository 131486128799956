import React from 'react';
import Link from 'adapter/next/link';
import { Anchor } from '@konsus/superside-kit';
import { cn } from '@superside/ui';
import {
  OpenCtaOptions,
  ProgressiveModalEnabledCta
} from '@konsus/lego/src/components/HeroCtaGroup';
import { HeaderDropButton, type HeaderDropButtonProps } from '../HeaderDropButton/HeaderDropButton';
import wrapperStyles from '../Wrapper/Wrapper.module.css';

const LINK_ITEM_CLASS_NAMES = [
  'header-menu-item',
  wrapperStyles.headerMenuLinkItem,
  'font-bold',
  'no-underline',
  'hover:font-bold',
  'hover:no-underline'
];

export interface HeaderMenuItemProps<T> {
  display: 'primary' | 'secondary' | 'link';
  href: string;
  linkText: string;
  submenuComponents?: Record<keyof T, HeaderDropButtonProps<T>['SubmenuComponent']>;
  submenuHeaderLinks?: Record<keyof T, HeaderDropButtonProps<T>['headerLink']>;
  type?: string;
  ctaType?: 'url' | 'modalWithProgressiveForm';
  predefinedForm?: any;
}

export function HeaderMenuItem<T>(props: HeaderMenuItemProps<T>) {
  if (props.type) {
    const submenuComponents = props.submenuComponents || {};
    const submenuHeaderLinks = props.submenuHeaderLinks || {};

    return (
      <HeaderDropButton
        // @ts-ignore 'string' can't be used to index type {}
        SubmenuComponent={submenuComponents[props.type]}
        // @ts-ignore 'string' can't be used to index type {}
        headerLink={submenuHeaderLinks[props.type]}
        className={cn(LINK_ITEM_CLASS_NAMES)}
        menuItem={props}
      />
    );
  }

  switch (props.display) {
    case 'primary':
      return (
        <HeaderPrimaryButton
          href={props.href}
          linkText={props.linkText}
          ctaType={props.ctaType}
          predefinedForm={props.predefinedForm}
          className='my-3'
        />
      );
    case 'secondary':
      return (
        <ProgressiveModalEnabledCta
          cta={{ ctaType: 'url', ctaLink: props.href, ctaText: props.linkText }}
          ctaOption={OpenCtaOptions.PRIMARY}
          variant='outline'
          className='my-3'
        />
      );
    case 'link':
    default:
      return (
        <Link legacyBehavior href={props.href} passHref>
          <Anchor className={cn(LINK_ITEM_CLASS_NAMES)} label={props.linkText} />
        </Link>
      );
  }
}

const HeaderPrimaryButton: React.FC<{
  href: string;
  linkText: string;
  ctaType?: 'url' | 'modalWithProgressiveForm';
  predefinedForm?: any;
  className?: string;
}> = ({ href, linkText, ctaType = 'url', predefinedForm, className }) => {
  return (
    <ProgressiveModalEnabledCta
      cta={{ ctaType, predefinedForm, ctaLink: href, ctaText: linkText }}
      ctaOption={OpenCtaOptions.PRIMARY}
      variant='primary'
      className={className}
    />
  );
};
