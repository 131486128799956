import React from 'react';
import { Button, Icon } from '@konsus/superside-kit';
import { ChevronDownThin } from '@konsus/superside-kit/src/images/icons';
import { cn } from '@superside/ui';
import { useMenu } from '../../utils/useMenu';
import { type HeaderNavigationDropdownMenuItem } from '../types';
import { HeaderSubmenu } from './menus/HeaderSubmenu';
import styles from './HeaderDropButton.module.css';

export interface HeaderDropButtonProps<T> {
  className?: string;
  menuItem: HeaderNavigationDropdownMenuItem<T>;
  SubmenuComponent: React.FC<{
    menuItem: HeaderNavigationDropdownMenuItem<T>;
  }>;
  headerLink?: {
    href: string;
    text: string;
  };
}

export function HeaderDropButton<T>({
  /* @ts-ignore */
  SubmenuComponent = HeaderSubmenu,
  menuItem,
  className,
  headerLink
}: HeaderDropButtonProps<T>) {
  const { onMouseEnter, onMouseLeave, toggleMenu, isOpen, hasJustOpened } = useMenu();

  return (
    <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Button
        className={cn(
          '1280:w-[auto] flex w-full flex-row items-center justify-between',
          className,
          'before:content-empty before:bg-white-700 relative before:absolute before:left-2/4 before:top-[50px] before:z-[1] before:-ml-1.5 before:block before:h-3.5 before:w-3.5 before:rotate-45 before:rounded',
          styles.tip,
          isOpen ? 'before:animate-slide-down-appearing before:opacity-100' : 'before:opacity-0'
        )}
        label={
          <>
            {/* @ts-ignore */}
            {menuItem.linkText}
            <Icon
              icon={ChevronDownThin}
              color='currentColor'
              className={cn(
                'transition-transform',
                'duration-200',
                'ease-in-out',
                'mr-2',
                '768:mr-4',
                'ml-1',
                'w-[18px]',
                'h-[18px]',
                '1280:mr-0',
                '1280:w-3',
                '1280:h-3',
                'relative',
                'left-[5px]',
                'top-[1px]',
                isOpen && 'rotate-180'
              )}
            />
          </>
        }
        onClick={!hasJustOpened ? toggleMenu : undefined}
        plain
      />
      <div
        className={cn(
          isOpen ? 'flex' : 'hidden',
          '1280:w-[calc(100%-5rem)]',
          '1280:fixed',
          '1280:top-12',
          '1280:mt-[34px]',
          '1280:translate-x-[-50%]',
          '1280:left-[50%]'
        )}
      >
        <div
          className={cn(
            'flex',
            isOpen ? ['animate-slide-down-appearing', 'opacity-100'] : 'opacity-0',
            'w-full'
          )}
        >
          <SubmenuComponent
            /* @ts-ignore */
            headerLink={headerLink}
            menuItem={menuItem}
            /* @ts-ignore */
            banner={menuItem.data.banner}
          />
        </div>
      </div>
    </span>
  );
}
