import { useEffect, useRef, useState } from 'react';

interface ObserverOptions {
  rootMargin?: string;
  threshold?: number;
}

const defaultOptions: ObserverOptions = {
  threshold: 0.1
};

const BOUNDING_RECT_MIN_Y = 100;

export function useStickyHeader(observerOptions = defaultOptions) {
  const wrapperRef = useRef<HTMLDivElement>();
  const helperRef = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState<boolean>(false);
  const [pinned, setPinned] = useState<boolean>(false);

  useEffect(() => {
    setSticky(true);

    let element;
    let observer;

    if (wrapperRef.current && typeof IntersectionObserver === 'function') {
      element = helperRef.current;
      observer = new IntersectionObserver(([e]) => {
        setPinned(e.intersectionRatio < 1 && e.boundingClientRect.y < BOUNDING_RECT_MIN_Y);
      }, observerOptions);

      observer.observe(element);
    }

    return () => {
      if (element) {
        observer?.unobserve(element);
      }
    };
  }, [observerOptions]);

  return {
    sticky,
    pinned,
    wrapperRef,
    helperRef
  };
}
