import React from 'react';
import dynamic from 'adapter/next/dynamic';
import { cn } from '@superside/ui';
import { defaultSanityClient } from '@konsus/sanity-client';
import { NextFutureImage, type SanityImage } from '@konsus/image';
import { EmailCapturingForm as FormComponent } from './EmailCapturingForm';

const SanityBlockComponent: any = dynamic(
  () => import('@konsus/sanity-components/src/SanityBlockComponent')
);

const EmailCapturingForm = dynamic(
  () => import(/* webpackChunkName: "tier-0" */ '@konsus/lego/src/components/EmailCapturingForm')
);

const BG_VARIANTS = {
  '#E5EAFF': '1280:bg-blue-100 1280:bg-blue-100',
  '#4F4FC8': '1280:bg-indigo-500 1280:bg-light',
  '#0F2133': '1280:bg-darker 1280:bg-light'
};

export const CapabilitiesBanner: React.FC<{
  banner: {
    variant?: string;
    backgroundColor?: string;
    bgImage?: SanityImage;
    form?;
    headerText?: string;
    subHeaderRichText?: string;
    superscriptText?: string;
  };
}> = ({ banner }) => {
  const {
    variant = 'withForm',
    backgroundColor = '#E5EAFF',
    bgImage,
    form,
    headerText,
    subHeaderRichText,
    superscriptText
  } = banner;

  return (
    <div
      className={cn(
        `1280:w-[304px] 1280:flex 1280:flex-col hidden ${BG_VARIANTS[backgroundColor]} group`
      )}
    >
      <div className='grow'>
        {bgImage ? (
          <NextFutureImage
            className='duration-500 ease-in-out group-hover:scale-[1.06]'
            image={bgImage}
            alt='headerText'
          />
        ) : null}
      </div>
      <div className='flex flex-col gap-2 p-6 pr-5 pt-0'>
        {superscriptText ? (
          <span className='text-xs font-semibold uppercase leading-normal tracking-[1.65px]'>
            {superscriptText}
          </span>
        ) : null}
        {headerText ? <h3 className='text-[23px] leading-8'>{headerText}</h3> : null}
        {subHeaderRichText ? (
          <div>
            <SanityBlockComponent
              sanityConfig={defaultSanityClient.clientConfig}
              blocks={subHeaderRichText}
            />
          </div>
        ) : null}
        {variant === 'withForm' && form ? (
          <div className='pt-2'>
            <EmailCapturingForm
              redirectLink='/get-started'
              FormComponent={FormComponent}
              {...form}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
