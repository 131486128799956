import { useState, useEffect } from 'react';

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState<number>();
  const [pageHeight, setPageHeight] = useState<number>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPageHeight(document.body.offsetHeight);

      const handleResize = () => {
        setWindowHeight(window.innerHeight);
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return { windowHeight, pageHeight };
};

export default useWindowHeight;
