/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'adapter/next/link';
import { cn } from '@superside/ui';
import { Icon } from '@konsus/superside-kit';
import { ChevronDownThin } from '@konsus/superside-kit/src/images/icons';
import { type BaseDropdownMenuItem } from '../../../types';
import { CapabilitiesBanner } from '../../../CapabilitiesBanner';
import { type BannerProps, BannerWithButton } from '../../../BannerWithButton';
import styles from './styles.module.css';
import { useMenuMaxHeight } from './useMenuMaxHeight';
import { HeaderSubmenuItem } from './HeaderSubmenuItem';

export interface MenuProps {
  menuItem: BaseDropdownMenuItem;
  banner?: BannerProps;
}

export const HeaderSubmenu: React.FC<MenuProps> = (props) => {
  const { data, linkText } = props.menuItem;
  const { items, enableReadMoreButton, button } = data;
  const totalItems = items.length;

  const { style, className } = useMenuMaxHeight({
    xl: Math.ceil(totalItems / 3),
    lg: Math.ceil(totalItems / 2)
  });

  return (
    <div
      className={cn(
        'w-full',
        'flex',
        '1280:bg-[#fafafa]',
        '1280:shadow-[0px_0px_32px_0px_rgba(15,33,51,0.10)]',
        '1280:w-[1362px]',
        '1280:max-w-[1362px]',
        '1280:rounded-[8px]',
        '1280:relative',
        '1280:-left-5',
        '1280:mx-auto',
        '1280:my-0',
        '1280:overflow-hidden'
      )}
    >
      <div
        className={cn(
          'w-full',
          'bg-light',
          props.banner
            ? '1280:w-[calc(100%-304px)]'
            : '1280:overflow-hidden 1280:rounded-tr-lg 1280:rounded-br-lg'
        )}
      >
        <div
          className={cn(
            'flex',
            'flex-col',
            'items-start',
            'w-full',
            styles.menuContainer,
            '1280:p-10',
            '1280:pr-9'
          )}
        >
          <span className='1280:mb-5 text-sm font-semibold uppercase tracking-[2.1px] text-[#646464]'>
            {linkText}
          </span>

          <ul
            className={cn(
              'flex',
              'flex-col',
              'content-start',
              'w-full',
              '1280:overflow-hidden',
              styles.listWrapper,
              className
            )}
            style={style as React.CSSProperties}
          >
            {items.map((item, index) => (
              <HeaderSubmenuItem key={index} {...item} />
            ))}
          </ul>

          {enableReadMoreButton && button ? (
            <Link legacyBehavior href={button.url || '/'} passHref>
              <a className='hover:bg-grey-200 bg-grey-100 mt-[18px] flex w-full items-center justify-center rounded-lg px-4 py-2 text-center font-semibold'>
                {button.label}
                <Icon
                  icon={ChevronDownThin}
                  color='currentColor'
                  className={cn('ml-2', 'w-3', 'h-3', 'rotate-[270deg]')}
                />
              </a>
            </Link>
          ) : null}
        </div>
      </div>
      {props.banner?.variant ? (
        <div
          className={cn(
            'hidden',
            '1280:flex',
            '1280:w-[304px]',
            '1280:overflow-hidden',
            '1280:rounded-tr-lg',
            '1280:rounded-br-lg'
          )}
        >
          {props.banner.variant === 'withButton' ? (
            <BannerWithButton banner={props.banner} />
          ) : null}
          {props.banner.variant === 'withForm' ? (
            <CapabilitiesBanner banner={props.banner} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
