import React from 'react';
import { cn } from '@superside/ui';
import { Box, useScreenSize, useStickyHeader, theme, type BoxProps } from '@konsus/superside-kit';
import { useAtomValue } from '@konsus/atoms';
import { pageVibeDarkMode } from '@konsus/lego/src/utils';
import wrapperStyles from './Wrapper.module.css';

interface WrapperProps extends Partial<BoxProps> {
  plain?: boolean;
  light?: boolean;
  isOpen?: boolean;
  className?: string;
  isLpStickyCta?: boolean;
  children: React.ReactNode;
  darkMode?: boolean;
}

export const Wrapper: React.FC<WrapperProps> = ({
  plain = false,
  light = false,
  isOpen,
  className,
  isLpStickyCta = false,
  children,
  background,
  darkMode,
  ...props
}) => {
  const { wrapperRef, helperRef, sticky, pinned } = useStickyHeader();
  const { breakpoints } = useScreenSize();
  const darkModeAtom = useAtomValue(pageVibeDarkMode);

  const isDark = darkMode ?? darkModeAtom;
  const isPinned = breakpoints?.desktop && pinned;
  const isMobileMenuOpen = !breakpoints?.xlarge && isOpen;

  return (
    <>
      <div id='top' ref={helperRef} />
      <Box
        ref={wrapperRef}
        background={background}
        {...props}
        className={cn(
          className,
          wrapperStyles.baseHeader,
          !plain && sticky && wrapperStyles.stickyHeader,
          ((!plain && (isPinned || isMobileMenuOpen)) || light) && wrapperStyles.pinnedHeader,
          isLpStickyCta && wrapperStyles.lpStickyCtaHeader,
          !isMobileMenuOpen && wrapperStyles.closedHeader
        )}
      >
        <Box
          className={cn(
            wrapperStyles.backgroundWrapper,
            light && wrapperStyles.lightBackgroundWrapper,
            light && plain && wrapperStyles.lightPlainBackgroundWrapper,
            light && sticky && isPinned && wrapperStyles.stickyLightBackgroundWrapper,
            !plain && sticky && isPinned && !isLpStickyCta ? wrapperStyles.withBoxShadow : null,
            isLpStickyCta && wrapperStyles.stickyCtaBgColor,
            isDark && !isPinned && !isMobileMenuOpen && 'dark'
          )}
          style={
            {
              '--animation-bg-color': isMobileMenuOpen ? 'white' : background
            } as React.CSSProperties
          }
        >
          <Box
            className={wrapperStyles.contentWrapper}
            fill='horizontal'
            direction='row'
            align='center'
            justify='between'
            margin={{ horizontal: 'auto' }}
            width={{ max: theme.maxWidth.xxlarge }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};
