import React, { type ReactNode, useCallback, useState } from 'react';
import { Box, HeaderLogo } from '@konsus/superside-kit';
import { type blockByType } from '@konsus/lego';
import { type SanityImage } from '@konsus/image';
import { Wrapper, WrapperWithStickyCta } from './Wrapper';
import { HeaderItems } from './HeaderItems';
import { MobileToggleButton } from './MobileToggleButton';
import { HeaderVariant, type WrapperLogoWithStickyCtaProps } from './types';
import { type HeaderMenuItemProps } from './HeaderMenuItem';

export type PageDataType = {
  content: {
    backgroundVariant: 'variant-1';
    blocks: Array<typeof blockByType>;
    header: HeaderVariant;
    isNewWebsite: boolean;
    key: string;
    metaDescription: string;
    metaTitle: string;
    openGraphImage: SanityImage;
    stickyCta: WrapperLogoWithStickyCtaProps;
    useStickyCta: boolean;
    showOnlyLogo?: boolean;
    enableThemeSwitch?: boolean;
  };
  // TODO add types for skeleton
  skeleton: any;
};

export interface HeaderLayoutProps<T> {
  style?: JSX.IntrinsicElements['div']['style'];
  className?: string;
  data?: PageDataType;
  light?: boolean;
  background?: string;
  disableNav?: boolean;
  disableMainNav?: boolean;
  plain?: boolean;
  isOpen?: boolean;
  navSettings?: {
    header: HeaderVariant;
  };
  submenuComponents?: HeaderMenuItemProps<T>['submenuComponents'];
  submenuHeaderLinks?: HeaderMenuItemProps<T>['submenuHeaderLinks'];
  children?: ReactNode | undefined;
  app?: 'superside' | 'superads';
  darkMode?: boolean;
}

export function HeaderLayout<T>(props: HeaderLayoutProps<T>) {
  const { data, style, light, children, navSettings } = props;
  const [isMenuOpen, setIsOpen] = useState(false);
  const toggleMenu = useCallback(() => setIsOpen((value) => !value), []);
  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const headerVariant = navSettings?.header;

  if (data?.content?.showOnlyLogo || headerVariant === HeaderVariant.ONLY_LOGO) {
    if (data?.content?.useStickyCta) {
      return (
        <WrapperWithStickyCta
          {...data?.content?.stickyCta}
          background={props.background}
          themeSwitchEnabled={data?.content?.enableThemeSwitch}
        />
      );
    }

    return <WrapperWithLogo style={style} plain navSettings={navSettings} light={light} />;
  }

  return (
    <WrapperWithLogo style={style} isOpen={isMenuOpen} {...props}>
      <MobileToggleButton isOpen={isMenuOpen} onClick={toggleMenu} closeMenu={closeMenu} />
      <HeaderItems
        isOpen={isMenuOpen}
        navItems={data?.skeleton.navigation.headerNavigation}
        videoStats={data?.skeleton.navigation.videoStats}
        submenuComponents={props.submenuComponents}
        submenuHeaderLinks={props.submenuHeaderLinks}
      />
      {children}
    </WrapperWithLogo>
  );
}

function WrapperWithLogo<T>({
  className,
  light,
  children,
  background,
  isOpen,
  plain,
  app,
  darkMode
}: HeaderLayoutProps<T>) {
  return (
    <Wrapper
      light={light}
      tag='header'
      wrap
      direction='column'
      fill='horizontal'
      className={className}
      background={background}
      isOpen={isOpen}
      plain={plain}
      darkMode={darkMode}
    >
      <Box flex={false} direction='row' align='center' justify='center'>
        <HeaderLogo url='/' app={app} />
      </Box>
      {children}
    </Wrapper>
  );
}

export default HeaderLayout;
