import React, { useEffect } from 'react';
import Router from 'adapter/next/router';
import { cn } from '@superside/ui';
import { Box, Button, Icon, theme } from '@konsus/superside-kit';
import { Close, Menu } from '@konsus/superside-kit/src/images/icons';
import wrapperStyles from '../Wrapper/Wrapper.module.css';

interface MobileToggleButtonProps {
  isOpen: boolean;
  onClick: () => void;
  closeMenu: () => void;
}

export const MobileToggleButton: React.FC<MobileToggleButtonProps> = ({
  onClick,
  closeMenu,
  isOpen
}) => {
  useEffect(() => {
    const handler = () => setTimeout(closeMenu, 200);

    Router.events.on('routeChangeStart', handler);

    return () => {
      Router.events.off('routeChangeStart', handler);
    };
  }, [closeMenu]);

  return (
    <Button
      className={cn(wrapperStyles.menuToggleBtn, 'hover:white', 'lg:hidden', 'mr-0')}
      label={
        <Box pad='small'>
          <Icon
            icon={isOpen ? Close : Menu}
            size='small'
            className='relative left-[9px] h-[27px] w-[27px] transition-colors duration-300 ease-in-out'
            color={isOpen ? theme.colors['secondary-4'] : theme.colors['alabaster-white']}
          />
        </Box>
      }
      onClick={onClick}
      plain
    />
  );
};
