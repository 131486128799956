import React from 'react';
import { cn } from '@superside/ui';
import { HeaderMenuItem, type HeaderMenuItemProps } from '../HeaderMenuItem';
import { type FeaturedVideoItem } from '../types';
import styles from './HeaderItems.module.css';

interface HeaderItemsProps {
  isOpen: boolean;
  navItems: any[];
  videoStats: FeaturedVideoItem[];
  submenuComponents?: HeaderMenuItemProps['submenuComponents'];
  submenuHeaderLinks?: HeaderMenuItemProps['submenuHeaderLinks'];
}

export const HeaderItems: React.FC<HeaderItemsProps> = ({
  navItems,
  isOpen,
  videoStats,
  submenuComponents,
  submenuHeaderLinks
}) => (
  <div
    className={cn(
      'basis-full',
      'flex-col',
      'grow-1',
      'max-w-full',
      'mx-auto',
      'my-0',
      'opacity-0',
      'order-1',
      'shrink-0',
      'pt-[12px]',
      '1280:gap-4',
      '1280:border-none',
      '1280:flex-1',
      '1280:flex-row',
      '1280:flex',
      '1280:items-center',
      '1280:justify-end',
      '1280:max-w-none',
      '1280:mx-auto',
      '1280:opacity-100',
      '1280:order-none',
      '1280:p-0',
      '1280:transform-none',
      isOpen
        ? ['open', 'flex', `after:content-empty`, 'after:w-0', 'after:h-4', styles.mobileOpen]
        : ['hidden'],
      styles.itemsWrapper
    )}
  >
    {navItems.map((item) => (
      <HeaderMenuItem
        key={item.linkText}
        {...item}
        videoStats={videoStats}
        submenuComponents={submenuComponents}
        submenuHeaderLinks={submenuHeaderLinks}
      />
    ))}
  </div>
);
