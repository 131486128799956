import React from 'react';
import Link from 'adapter/next/link';
import { Box, Logo } from '@superside/ui';
import { type Spacing } from '../../styles';

export const HeaderLogo: React.FunctionComponent<{
  visible?: boolean;
  url?: string;
  header?: string;
  LogoComponent?: React.FC<{ pad?: Spacing }>;
  app?: 'superside' | 'superads';
}> = (props) => {
  const { visible = true, url = '/', header = 'onlyLogo', LogoComponent = Logo, app } = props;

  return (
    <Link legacyBehavior href={url} passHref>
      <a className='headerLogoAnchor w-full no-underline'>
        {visible && header === 'onlyLogo' ? (
          <Box
            className={
              app === 'superads'
                ? '768:h-10 768:w-[222px] [&_svg]:768:!h-10 my-1 w-[172px] [&_svg]:!h-7'
                : '[&>div>svg]:768:max-h-8 578:w-[160px] 578:h-[74px] 578:my-0 mx-0 my-1 h-[46px] w-[115px] justify-center [&>div>svg]:max-h-6'
            }
          >
            <LogoComponent app={app} className='h-full [&_svg]:min-h-full' />
          </Box>
        ) : null}
      </a>
    </Link>
  );
};

export default HeaderLogo;
