import React, { useCallback, useState } from 'react';
import { EmailIcon } from './EmailIcon';

export const EmailCapturingForm: React.FC<{
  validate;
  onSubmit;
  placeholder?: string;
  buttonLabel: string;
}> = (props) => {
  const { validate, onSubmit, placeholder = 'buzz@nasa.gov', buttonLabel } = props;
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(null);

  const inputOnChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const errors: any = validate({ email });

      if (errors.email) {
        setError(errors.email);

        return;
      }
      setLoading(true);
      onSubmit({ email }).then(() => setLoading(false));
    },
    [email, validate, onSubmit]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex flex-col gap-2'>
        <div className='relative'>
          <div className='absolute left-4 top-[7px]'>
            <EmailIcon />
          </div>
          <input
            className='semibold border-white-200 w-full rounded border border-solid p-2 pl-[47px] text-sm'
            type='email'
            placeholder={placeholder}
            onChange={inputOnChange}
          />
        </div>
        <div>
          <button
            type='submit'
            disabled={loading}
            className='bg-darker hover:text-dark hover:border-black-500 text-grey-100 w-full rounded px-8 py-2 text-center text-sm font-semibold not-italic leading-[150%] hover:border-2 hover:border-solid hover:bg-transparent hover:py-1.5'
          >
            {loading ? 'Loading' : buttonLabel}
          </button>
        </div>

        {error ? (
          <span className='text-coral-600 text-bor-sm absolute bottom-[3px]'>{error}</span>
        ) : null}
      </div>
    </form>
  );
};

EmailCapturingForm.displayName = 'EmailCapturingForm';
