import styles from './styles.module.css';

export const LIST_ITEM_GAP_PX = '11px';
export const ITEM_HEIGHT = '70px';

export const useMenuMaxHeight = ({ lg: itemsPerColumnLg, xl: itemsPerColumnXl }) => {
  return {
    className: styles.menuMaxHeight,
    style: {
      '--max-height-xl':
        itemsPerColumnXl * parseInt(ITEM_HEIGHT) +
        (itemsPerColumnXl - 1) * parseInt(LIST_ITEM_GAP_PX) +
        'px',
      '--max-height-md':
        itemsPerColumnLg * parseInt(ITEM_HEIGHT) +
        (itemsPerColumnLg - 1) * parseInt(LIST_ITEM_GAP_PX) +
        'px'
    }
  };
};
