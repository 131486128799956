import { useCallback, useEffect, useRef, useState } from 'react';
import Router from 'adapter/next/router';
import { useScreenSize } from '@konsus/superside-kit';

type Timeout = ReturnType<typeof setTimeout>;

// time where hasJustOpened stays true after opening the menu
const HAS_JUST_OPENED_TIMEOUT = 500;

export const useMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasJustOpened, setHasJustOpened] = useState<boolean>(false);
  const timeout = useRef<Timeout>(null);
  const { breakpoints } = useScreenSize();

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openMenu = useCallback(() => {
    setIsOpen(true);
    setHasJustOpened(true);
    setTimeout(() => setHasJustOpened(false), HAS_JUST_OPENED_TIMEOUT);
  }, []);

  const toggleMenu = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  const onMouseEnter = useCallback(() => {
    if (!breakpoints.desktop) {
      return;
    }
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => openMenu(), 100);
  }, [openMenu, breakpoints.desktop]);

  const onMouseLeave = useCallback(() => {
    if (!breakpoints.desktop) {
      return;
    }
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => closeMenu(), 100);
  }, [closeMenu, breakpoints.desktop]);

  useEffect(() => {
    const handler = () => setTimeout(closeMenu, 200);

    Router.events.on('routeChangeStart', handler);

    return () => {
      Router.events.off('routeChangeStart', handler);
    };
  }, [closeMenu]);

  return {
    isOpen,
    hasJustOpened,
    openMenu,
    closeMenu,
    toggleMenu,
    onMouseEnter,
    onMouseLeave
  };
};
